import { PayloadAction } from "@reduxjs/toolkit";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TNonTermAddForm } from "../../../types/NonTerm/TNonTermAddForm";
import {
  THDFCRidersBenefitsResponse,
  TRidersBenefitsResponse,
} from "../../../types/NonTerm/TNonTermRidersBenifits";
import {
  DROPDOWN_MASTER,
  TNonTermProposerData,
  TNonTermQuoteFieldData,
  TNonTermSlice,
} from "../../../types/TNonTermSlice";

function NON_TERM_UPDATE_ADD_FORM_DATA(
  state: TNonTermSlice,
  action: PayloadAction<TNonTermAddForm>
) {
  const data: TNonTermSlice = { ...state, ADD_FORM: { ...action.payload } };

  return data;
}

function UPDATE_PROPOSER_DATA(
  state: TNonTermSlice,
  action: PayloadAction<TNonTermProposerData>
) {
  const data: TNonTermSlice = {
    ...state,
    PROPOSER_DATA: { ...action.payload },
  };

  return data;
}
function BULK_UPDATE_DATA(
  state: TNonTermSlice,
  action: PayloadAction<Partial<TNonTermSlice>>
) {
  const updatedProperties: Partial<TNonTermSlice> = action.payload;
  const updatedState: TNonTermSlice = { ...state, ...updatedProperties };

  return updatedState;
}

const UPDATE_QUOTE_LOADER_STATUS = (
  state: TNonTermSlice,
  action: PayloadAction<boolean>
) => {
  console.log("statete", action.payload);
  const data = {
    ...state,
    QUOTE_LOADER: action.payload,
  };

  return data;
};

function UPDATE_QUOTE_FIELD_DATA1(
  state: TNonTermSlice,
  action: PayloadAction<any>
) {
  const { attrName, value } = action.payload;
  const data: TNonTermSlice = {
    ...state,
    QUOTE_FIELDS_DATA: {
      ...state.QUOTE_FIELDS_DATA,
      [attrName]: value,
    },
  };

  return data;
}

function UPDATE_QUOTE_FIELD_BULK(
  state: TNonTermSlice,
  action: PayloadAction<TNonTermQuoteFieldData>
) {
  const data: TNonTermSlice = {
    ...state,
    QUOTE_FIELDS_DATA: {
      ...action.payload,
    },
  };

  return data;
}

function UPDATE_RESPONSE_DATA(
  state: TNonTermSlice,
  action: PayloadAction<Partial<TNonTermSlice>>
) {
  const updatedProperties: Partial<TNonTermSlice> = action.payload;
  const updatedState: TNonTermSlice = { ...state, ...updatedProperties };

  return updatedState;
}

function UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT(
  state: TNonTermSlice,
  action: PayloadAction<{
    updates: Array<{ key: string; value: boolean | string }>;
  }>
) {
  const { updates } = action.payload;
  let data = { ...state };

  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...data.RIDERS_BENEFITS,
      local_value: {
        ...data.RIDERS_BENEFITS.local_value,
        ...updates.reduce((updatedProperties, { key, value }) => {
          return { ...updatedProperties, [key]: value };
        }, {}),
      },
    },
  };

  return data;
}

function UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
  state: TNonTermSlice,
  action: PayloadAction<{
    updates: Array<{ key: string; value: boolean | string }>;
  }>
) {
  const { updates } = action.payload;
  let data = { ...state };

  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...data.RIDERS_BENEFITS,
      local_value: {
        ...data.RIDERS_BENEFITS.local_value,
        ...updates.reduce((updatedProperties, { key, value }) => {
          return { ...updatedProperties, [key]: value };
        }, {}),
      },
    },
  };

  return data;
}

function SET_RIDERS_BENEFITS_DATA(
  state: TNonTermSlice,
  action: PayloadAction<TRidersBenefitsResponse>
) {
  let data = { ...state };
  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...state.RIDERS_BENEFITS,
      riders_benefits_response: action.payload,
    },
  };

  return data;
}

function SET_HDFC_RIDERS_BENEFITS_DATA(
  state: TNonTermSlice,
  action: PayloadAction<THDFCRidersBenefitsResponse>
) {
  let data = { ...state };
  data = {
    ...data,
    RIDERS_BENEFITS: {
      ...state.RIDERS_BENEFITS,
      riders_benefits_response: action.payload,
    },
  };

  return data;
}

function SET_DROPDOWN_DATA(
  state: TNonTermSlice,
  action: PayloadAction<{ key: keyof DROPDOWN_MASTER; value: TDropdown[] }>
) {
  const { key, value } = action.payload;
  let data: TNonTermSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}

const SET_PAGE_STATUS=(state : TNonTermSlice, action : PayloadAction<number>) =>{
  console.log(action.payload, "gfgg")
  state.ADD_FORM.page_status = action.payload;
}

export const NON_TERM_REDUCERS = {
  SET_PAGE_STATUS,
  NON_TERM_UPDATE_ADD_FORM_DATA,
  BULK_UPDATE_DATA,
  UPDATE_QUOTE_LOADER_STATUS,
  UPDATE_QUOTE_FIELD_DATA1,
  UPDATE_QUOTE_FIELD_BULK,
  UPDATE_RESPONSE_DATA,
  UPDATE_PROPOSER_DATA,
  UPDATE_LOCAL_VALUE_ICICI_RIDERS_BENEFIT,
  UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT,
  SET_RIDERS_BENEFITS_DATA,
  SET_DROPDOWN_DATA,
  SET_HDFC_RIDERS_BENEFITS_DATA,
  //   UPDATE_DROPDOWN_DATA,
  //   setTermAddFormData,
  //   setTermAddFormDataResponse,
  //   UPDATE_ADD_FORM_RESPONSE,
  //   UPDATE_QUOTE_FIELD_DATA,
  //   SET_SELECTED_QUOTE_DATA,
  //   ADD_PREMIUM,
  //   SUB_PREMIUM,
  //   UPDATE_RIDERS,
  //   UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT,
  //   SET_FIRST_TIME_QUOTE_LOADER,
  //   UPDATE_LOADER_STATUS,
};

export type TwoTermReducers = typeof NON_TERM_REDUCERS;
